<template>
  <!-- <section> -->
  <v-card
    width="450"
    max-width="85%"
    class="py-4 glb-border-top"
    :color="$store.getters.theme === 'dark' ? '#000000b3' : '#e8eff7'"
    :dark="$store.getters.theme === 'dark' ? true : false"
  >
    <v-card-text class="text-h6">
      Hi {{ nickName }}, Select a password (Mnemonic) for your account.
    </v-card-text>

    <v-textarea
      outlined
      readonly
      class="pa-5 pb-0"
      label="Password (Mnemonic)"
      v-model="mnemonic"
      @focus="$event.target.select()"
      hide-details
      rows="3"
    >
    </v-textarea>

    <v-card-text>
      use the above phrase as password, COPY and STORE this somewhere safe and
      secret
    </v-card-text>

    <v-card-actions class="mb-2">
      <v-layout justify-space-around>
        <v-btn
          width="48%"
          :disabled="isDownloadProcessing"
          text
          class="main-color"
          @click="saveAsFile()"
        >
          <b>Download Mnemonic</b>
        </v-btn>

        <v-btn
          width="48%"
          class="main-btn"
          @click="createAccount()"
          :loading="isLoading"
        >
          Create Account
        </v-btn>
      </v-layout>
    </v-card-actions>

    <v-card-text>
      <div class="form-tag text-center">
        <strong>
          Already have an account?
          <router-link to="/auth/login" class="main-color">
            Sign in
          </router-link>
        </strong>
      </div>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-text>
      <b>
        <span class="red--text">Note:</span> Once password is lost you won't be
        able to retrieve it back.
      </b>
    </v-card-text>
    <v-bottom-sheet
      v-model="showBottomSheet"
      class="glb-border-top"
      :color="$store.getters.theme === 'dark' ? '#000000b3' : '#e8eff7'"
      :dark="$store.getters.theme === 'dark' ? true : false"
    >
      <v-sheet class="text-center" min-height="200px">
        <div class="pt-10">Your Password (Mnemonic's) are not saved!</div>
        <v-btn
          class="mt-6 mx-1"
          color="primary"
          @click="showBottomSheet = !showBottomSheet"
        >
          Download Mnemonic
        </v-btn>
        <v-btn class="mt-6 mx-1" color="primary" @click="createAccount(true)">
          Copied? continue
        </v-btn>
      </v-sheet>
    </v-bottom-sheet>
  </v-card>

  <!-- <div class="text-center">
    </div> -->
  <!-- </section> -->
</template>

<script>
import {
  generateMnemonic,
  getExtPublicKey,
  getPrivateKey,
  getPublicKey,
} from "../../../plugins/ledgermail-jslib";
import { verifyEmail } from "../../../plugins/axios";

export default {
  props: ["nickName", "emailId"],

  created() {
    this.mnemonic = generateMnemonic();
  },

  data: () => ({
    mnemonic: "",

    isLoading: false,
    isDownloadProcessing: false,
    isFileDownloaded: false,

    showBottomSheet: false,
  }),

  methods: {
    saveAsFile() {
      this.isDownloadProcessing = true;
      var hiddenElement = document.createElement("a");
      hiddenElement.href = "data:attachment/text," + encodeURI(this.mnemonic);

      hiddenElement.target = "_blank";
      hiddenElement.download = this.nickName + ".txt";
      hiddenElement.click();
      this.isDownloadProcessing = false;
      this.isFileDownloaded = true;
    },
    createAccount(force = false) {
      if (!force && !this.isFileDownloaded) {
        this.showBottomSheet = true;
        return this.$toast("Mnemonic's were not stored!");
      }

      if (force) this.showBottomSheet = false;

      this.isLoading = true;

      setTimeout(async () => {
        const xPublicKey = await getExtPublicKey(this.mnemonic);
        const publicKey = await getPublicKey(this.mnemonic);
        const privateKey = await getPrivateKey(this.mnemonic);

        try {
          const userCheck = (await verifyEmail(this.emailId, xPublicKey)).data;

          if (userCheck.isEmail) throw new Error("Email already exist!");
          if (userCheck.exist) {
            this.mnemonic = generateMnemonic();
            throw new Error("Server busy, Please try again!");
          }

          this.$emit("createAccount", {
            extPublicKey: xPublicKey,
            publicKey,
            privateKey,
          });
        } catch (error) {
          console.error(error);
          if (error.message) this.$toast.error(error.message);
          else this.$toast.error("Unexpected error! Please try after sometime");
        }
        this.isLoading = false;
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.glb-dark {
  .main-btn {
    color: white !important;
  }
}

.glb-white {
  .main-btn {
    color: black !important;
  }
}
</style>
