<template>
  <v-layout fill-height align-center justify-center>
    <section v-if="!getMnemonics">
      <h2 class="text-center mb-10">Sign up</h2>
      <v-form
        ref="login-form"
        @submit.prevent
        class="login-form"
        lazy-validation
      >
        <div class="mb-2">Email address</div>
        <v-text-field
          class="mb-6"
          placeholder="Email used in Hashtag account"
          v-model.trim="email"
          hide-details
          rounded
          height="60"
          solo
          :readonly="isLoading"
          light
          @keydown.enter="getHashtagInfo()"
        ></v-text-field>

        <v-btn
          @click="getHashtagInfo()"
          class="main-btn my-3"
          :class="$store.getters.theme === 'dark' ? 'white--text' : ''"
          width="100%"
          rounded
          x-large
          :loading="isLoading"
        >
          <b>Next</b>
        </v-btn>
      </v-form>
      <div class="form-tag text-center">
        <strong>
          Already have an account?
          <router-link to="/auth/login" class="main-color">
            Sign in
          </router-link>
        </strong>
      </div>
    </section>
    <show-mnemonics
      v-else
      :emailId="this.params.emailId"
      :nickName="this.params.nickName"
      @createAccount="createAccount"
    ></show-mnemonics>
  </v-layout>
</template>

<script>
import {
  getHashTagDetails,
  getHashTagDemoDetails,
  getLocalCookie,
} from "../../plugins/api/hashtag-api";
import { getSignature } from "../../plugins/axios";
import { createUser } from "../../plugins/api/user-api";

import ShowMnemonics from "./signup-components/ShowMnemonics";

export default {
  components: { ShowMnemonics },

  data: () => ({
    email: "",

    isLoading: false,
    getMnemonics: false,

    params: {
      firstName: "",
      lastName: "",
      emailId: "",
      img: "",
      nickName: "",
      role: "",
      publicKey: "",
      extPublicKey: "",
    },
  }),
  created() {
    const data = JSON.parse(getLocalCookie("_mh_usr") || "{}");

    if (!data || !data.email)
      return this.$toast(
        "Please Signin to Hashtag.space before accessing D-Mail"
      );

    this.email = data.email;
  },

  methods: {
    async getHashtagInfo() {
      if (this.isLoading) return;

      const data = JSON.parse(getLocalCookie("_mh_usr"));

      if (!data || !data.email)
        return this.$toast(
          "Please login to Hashtag.space Before accessing D-Mail"
        );

      if (this.email !== data.email) {
        this.$toast.error(
          "Provided Email is mismatching with logged in user from Hashtag.space."
        );
        setTimeout(() => {
          this.$toast(
            "Please use the same Email used to Sign in to Hashtag.space"
          );
        }, 1000);
        return;
      }

      if (this.email.length === 0)
        return this.$toast.error("Please provide your Email");

      if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email))
        return this.$toast.error("Invalid E-mail!");

      this.isLoading = true;

      try {
        let hashtagResponse;
        if (
          process.env.VUE_APP_CLIENT_URL &&
          process.env.VUE_APP_CLIENT_URL.split("8080")[1] === "/api/demo"
        ) {
          hashtagResponse = (await getHashTagDemoDetails([this.email])).data;
        } else hashtagResponse = (await getHashTagDetails([this.email])).data;

        if (hashtagResponse.error || !hashtagResponse.result)
          return this.$toast.error("#404, Server not found.");

        if (!hashtagResponse.result[this.email])
          throw new Error(
            "Please create account in Hashtag.space before accessing D-Mail."
          );

        if (
          !hashtagResponse.result[this.email].nickName ||
          !hashtagResponse.result[this.email].nickName.length
        )
          throw new Error(
            "Please create a 'Nickname' for your Hashtag account before using D-Mail."
          );

        this.params.firstName = hashtagResponse.result[this.email].first_name;
        this.params.lastName = hashtagResponse.result[this.email].last_name;
        this.params.emailId = hashtagResponse.result[this.email].email;
        this.params.img = hashtagResponse.result[this.email].img;
        this.params.nickName =
          "#" + hashtagResponse.result[this.email].nickName;
        this.params.role = hashtagResponse.result[this.email].role;

        this.getMnemonics = true;
      } catch (error) {
        console.error(error);
        if (error.message) this.$toast.error(error.message);
        else this.$toast.error("Unexpected error! Please try after sometime");
      }
      this.isLoading = false;
    },
    async createAccount({ extPublicKey, publicKey, privateKey }) {
      this.params.extPublicKey = extPublicKey;
      this.params.publicKey = publicKey;
      this.params.privateKey = privateKey;

      try {
        const signatureData = (await this.getSignature()).data;
        if (signatureData.error || !signatureData.created)
          throw new Error(
            signatureData.error ? signatureData.error : "#404, Server not found"
          );

        const createUserResponse = await this.createUser(signatureData);

        if (createUserResponse.success) {
          this.$toast.success("Account created!");
          this.$router.push("/auth");
        } else {
          throw new Error(
            createUserResponse.message
              ? createUserResponse.message
              : "Transaction terminated! Please try after sometime."
          );
        }
      } catch (error) {
        console.error(error);
        if (error.message) this.$toast.error(error.message);
        else this.$toast.error("Unexpected error! Please try after sometime");
      }
    },
    getSignature() {
      return getSignature(
        "CREATE_USER",
        {
          firstName: this.params.firstName,
          lastName: this.params.lastName,
          publicKey: this.params.publicKey,
          extPublicKey: this.params.extPublicKey,
        },
        this.params.emailId,
        this.params.img,
        this.params.nickName,
        this.params.role
      );
    },
    createUser({ signature, created }) {
      return createUser(
        this.params.privateKey,
        {
          firstName: this.params.firstName,
          lastName: this.params.lastName,
          emailId: this.params.emailId,
          img: this.params.img,
          publicKey: this.params.publicKey,
          extPublicKey: this.params.extPublicKey,
          nickName: this.params.nickName,
          role: this.params.role,
          created,
        },
        signature
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.login-form {
  width: 450px;
}

.form-tag {
  font-size: 20px;

  span {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
</style>
